import styled from "styled-components";

import { MOBILE_460, TABLET_768 } from "../../styles/globals/sizes";

export const ServiceAreaContainer = styled.div`
  width: 100%;
  padding: 70px 60px 80px;
  display: flex;
  flex-direction: column;
  background-color: #F2F2F2;
  div {
    max-width: 1500px;
    margin: 0 auto;
  }
  @media (max-width: ${TABLET_768}px) {
    padding: 40px 20px;
  }
`;

export const ServiceAreaTitleContainer = styled.div`
  width: 100%;
  margin-bottom: 43px !important;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${TABLET_768}px) {
    margin-bottom: 24px !important;
    justify-content: flex-start;
  }

  @media (max-width: ${MOBILE_460}px) {
    width: 95%;
  }
`;

export const ServiceAreaTitle = styled.h2`
  font: normal normal bold 37px/49px Roboto;
  color: #E0281A;

  @media (max-width: ${TABLET_768}px) {

    font: normal normal bold 30px/39px Roboto;
  }
`;

export const MapContainer = styled.a`
  width: 388px;
  box-shadow: #63636333 0px 0px 8px 0px;
  border-radius: 10px;
`;

export const MapImageContainer = styled.div`
  & > img {
    width: 100%;
    margin-right: 0%;
    object-fit: cover;
  }
`;

export const MapDescription = styled.div`
  padding: 23px 20px 18px;
  display: flex;
  flex-direction: column;
  font: normal normal normal 16px/18px Roboto;
  color: #404040;
  align-items: flex-start;
  box-shadow: #63636333 0px 0px 8px 0px;
  border-radius:  0 0px 10px 10px;

  span {
    font-weight: bold;
  }
  @media (max-width: ${TABLET_768}px) {
    padding: 18px 20px 10px;
  }
`;

export const LastText = styled.p`
  margin-top: 15px;
`;

export const MapsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 40px;

  @media (max-width: ${TABLET_768}px) {
    display: none;
  }
`;

export const MapsContainerMobile = styled.div`
  width: 100%;
  display: none;
 
  @media (max-width: ${TABLET_768}px) {
    display: block;
  }
`;

export const SliderContainer = styled.div`
     width: 100%;
     height: 100%;

     .swiper-slide {
      height: auto;
      }
      .swiper{
        padding-bottom: 35px;
      }
      .swiper-pagination{
        bottom: 0;
      }
      span.swiper-pagination-bullet.swiper-pagination-bullet-active {
          background-color: #E01A73;
          opacity: 1;
          width: 12px;
          height: 12px;
        }

      .swiper-pagination-bullet {
        background-color: #CCCCCC;
        opacity: 1;
        width: 12px;
        height: 12px;
      }
`;
