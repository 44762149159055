import React from "react";
import { graphql, StaticQuery } from "gatsby";

import transformToSlug from "../utils";
import MapImage from "./assets/map.svg";
import {
  Button,
  ButtonsContainer,
  LocationsContainer,
  MapImageContainer,
} from './LocationsIndex.styles'
const LocationsIndexComponent = (data) => {
  const cmsData = data?.data?.cms?.locationsCounties?.data || [];
  const counties = [];
  cmsData.forEach(data => {
    if(data.attributes.County) {
      counties.push(data.attributes.County);
    }
  });
  return (
      <LocationsContainer>
        {counties.length > 0 && 
        <ButtonsContainer>
          {counties.map((county) => 
            <Button key={county} href={`/${transformToSlug(county)}/`}>
              {county}
            </Button>
          )}
        </ButtonsContainer>
        }
        <MapImageContainer src={MapImage} alt="Map"/>
      </LocationsContainer>
  );
};

const LocationsIndex = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          cms {
            locationsCounties(pagination: { limit: -1 }) {
              data {
                attributes {
                  County
                }
              }
            }
          }
        }
      `}
      render={(data) => <LocationsIndexComponent data={data} />}
    />
  );
};

export default LocationsIndex;
