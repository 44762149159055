import styled from "styled-components";
import { COLORS } from "../../styles/globals/colors";
import { DESKTOP_1024, DESKTOP_1200, DESKTOP_1280, TABLET_768 } from "../../styles/globals/sizes";

export const LocationsContainer = styled.div`
  width: 100%;
  max-width: 1500px;
  padding: 40px 60px 55px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  gap: 75px;

  @media (max-width: ${DESKTOP_1024}px) {
    padding: 36px 20px 65px;
  }

  @media (max-width: ${TABLET_768}px) {
    padding-top: 16px;
  }
`;

export const MapImageContainer = styled.img`
  width: 283px;
  height: 630px;

  @media (max-width: ${DESKTOP_1024}px) {
    display: none;
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-self: center;
  justify-content: center;
  gap: 40px;


  @media (max-width: ${DESKTOP_1024}px) {
    width: 374px;
    gap: 24px;
  }
  `;

export const Button = styled.a`
  width: 46%;
  height: 80px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background-color: ${COLORS.white};
  border: 2px solid ${COLORS.redTitle};
  color: ${COLORS.redTitle};
  font: normal normal 500 36px/30px 'Roboto';

  &:hover {
    background-color: ${COLORS.redTitle};
    color: ${COLORS.white};
  }

  @media (max-width: ${DESKTOP_1200}px) {
    padding: 20px 10px;
    height: 100%;
    text-align: center;
  }

  @media (max-width: ${DESKTOP_1024}px) {
    width: 100%;
    height: 64px;
    font-size: 30px;
    line-height: 30px;
  }
`;


