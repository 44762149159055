import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

import GetInTouchSection from "../components/GetInTouchSection";
import Hero from "../images/locations/hero.webp";
import HeroMobile from "../images/locations/mobile-hero.png";
import Layout from "../components/layout";
import LocationsIndex from "../components/Locations/LocationsIndex";
import Seoinfo from "../components/seoinfo/seoinfo";
import ServiceArea from "../components/ServiceArea/ServiceArea.jsx";
import seoImage from "../../static/seo/seo_image.png";

import {
  HeroContainer,
  HeroPicture,
  HeroPictureMobile,
  HeroTextDiv,
  InternalWrapper,
  Paragraph,
  PhoneAndParagraph,
  Title,
} from "../styles/pages/locations.jsx";
import { CONTACT } from "../config/urls.jsx";

const Locations = () => {
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <HelmetProvider key={isClient ? "client" : "server"}>
      <Layout hasTransparentTopBar>
        <Helmet>
          <link rel="canonical" href="https://homecare.life/locations" />
        </Helmet>
        <Seoinfo
          siteName="Discover Top Life Homecare Locations Near You"
          titlePage="Discover Top Life Homecare Locations Near You"
          description="Explore convenient Life Homecare locations for quality care services. Find the best Homecare today!"
          keywords="Home, Care, HomeCare, Senior, Compassionate, Care, Home Care Agency, Home Care Agencies"
          type="website"
          url="/locations"
          imageFacebook={seoImage}
        />
        <HeroContainer>
          <HeroPicture>
            <source srcSet={Hero} type="image/avif" />
            <img src={Hero} alt="Smiling Life Homecare Nurse" />
          </HeroPicture>
          <HeroPictureMobile>
            <source srcSet={HeroMobile} type="image/avif" />
            <img src={HeroMobile} alt="Smiling Life Homecare Nurse" />
          </HeroPictureMobile>
          <InternalWrapper data-aos="fade">
            <HeroTextDiv>
              <Title>OUR HOME CARE SERVICE AREA</Title>
              <PhoneAndParagraph>
                <Paragraph>
                  Our dedicated home care services are available in a variety of
                  locations, ensuring your loved ones receive the warmth and
                  support they deserve.
                </Paragraph>
              </PhoneAndParagraph>
            </HeroTextDiv>
          </InternalWrapper>
        </HeroContainer>
        <LocationsIndex />
        <ServiceArea />
        <GetInTouchSection contactEmail={CONTACT.MAIL_CONTACT_US_LOCATIONS} />
      </Layout>
    </HelmetProvider>
  );
};

export default Locations;
